import {cleanString,} from '../../utils/formatters';
import {
    ADD_NEW_CATEGORY,
    ADD_NEW_FACADE_IMAGE,
    ADD_NEW_FEATURE,
    ADD_NEW_HAPPY_INTERVAL,
    ADD_NEW_MESAYA_SECTOR,
    ADD_NEW_OPENING_INTERVAL,
    ADD_NEW_SECTOR,
    ADD_NEW_TABLES_IMAGE,
    ADD_NEW_TAG,
    ADD_NEW_ZONE,
    ADD_SHIFT,
    CHANGE_ACCEPTANCE_RADIUS,
    CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    CHANGE_BIG_GROUP,
    CHANGE_BILLING_INFO,
    CHANGE_BOOKING_NOTIFICATION,
    CHANGE_CLOSE_TIME,
    CHANGE_INFORMATION_DESCRIPTION,
    CHANGE_INFORMATION_TITLE,
    CHANGE_MAX_CAPACITY,
    CHANGE_NEW_ADDRESS,
    CHANGE_NEW_CITY,
    CHANGE_NEW_COUNTRY,
    CHANGE_NEW_DESCRIPTION,
    CHANGE_NEW_EMAIL,
    CHANGE_NEW_ID,
    CHANGE_NEW_LATITUDE,
    CHANGE_NEW_LOGO_IMAGE,
    CHANGE_NEW_LONGITUDE,
    CHANGE_NEW_NAME,
    CHANGE_NEW_PASSWORD,
    CHANGE_NEW_POSTAL_CODE,
    CHANGE_NEW_PRICE,
    CHANGE_NEW_PROVINCE,
    CHANGE_NEW_SCORE,
    CHANGE_NEW_USERNAME,
    CHANGE_SECTORS_VISUALIZATION,
    CHANGE_STAYING_RADIUS,
    CHANGE_TOLERANCE,
    NEW_ADDRESS_FOUND,
    NEW_PARTNER_ERROR,
    NEW_PARTNER_SUCCESS,
    NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION,
    REMOVE_NEW_CATEGORY,
    REMOVE_NEW_FACADE_IMAGE,
    REMOVE_NEW_FEATURE,
    REMOVE_NEW_HAPPY_INTERVAL,
    REMOVE_NEW_MESAYA_SECTOR,
    REMOVE_NEW_OPENING_INTERVAL,
    REMOVE_NEW_SECTOR,
    REMOVE_NEW_TABLES_IMAGE,
    REMOVE_NEW_TAG,
    REMOVE_NEW_ZONE,
    REMOVE_SHIFT,
    RESET_ALL_DATA,
    SWITCH_BOOKING_NOTIFICATION_ENABLE,
    SWITCH_GOOGLE_PLACES_DIALOG,
    TOGGLE_LOADING,
    TOGGLE_LOADING_GOOGLE_PLACES
} from '../../constants';

import uuid from 'react-uuid'

const initState = {
    id: uuid(),
    name: '',
    address: '',
    addressFound: null,
    description: '',
    latitude: 0,
    longitude: 0,
    city: '',
    province: '',
    country: '',
    postal_code: '',
    price: 0,
    score: 0,
    sectors: [],
    categories: [],
    tags: [],
    features: [],
    zones: [],
    opening_intervals: [],
    happy_intervals: [],

    shifts: [],

    acceptanceRadius: 1000,
    stayingRadius: 1500,
    maxCapacity: 25,
    bigGroup: '',
    tolerance: 10,
    closeTime: '00:30',

    autoAcceptancePercentage: 80,
    bookingNotification: 180,
    sectorsVisualization: true,
    mesayaSectors: [],
    bookingNotificationEnabled: true,

    newLogoImage: null,
    newFacadeImage: [],
    newTablesImage: [],

    newUserName: '',
    newUserEmail: '',
    newUserPassword: '',

    googlePlacesDialog: false,
    loadingPlace: false,

    informationTitle: '',
    informationDescription: '',

    name_billing: '',
    last_name_billing: '',
    cuit_billing: '',
    phone_billing: '',
    country_billing: '',
    city_billing: '',
    address_billing: '',
    zip_code_billing: '',
    businessName_billing: '',
    fantasyName_billing: '',
    legal_billing: "juridica",
    email_billing: '',
    plan_billing: '',
    availableCountries_billing: [],


    error: '',
    loading: false,
}

const createReducer = (state = initState, action) => {
    switch (action.type) {
        case NEW_PARTNER_SUCCESS:
            return initState;
        case NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION:
            return {...initState, id: uuid(), error: 'Partner creado, no se pudo realizar la suscripción'}
        case NEW_PARTNER_ERROR:
            return {...state, error: action.payload, loading: false};
        case CHANGE_NEW_ID:
            return {...state, id: action.payload};
        case CHANGE_NEW_SCORE:
            return {...state, score: action.payload};
        case CHANGE_NEW_NAME:
            return {...state, name: action.payload};
        case CHANGE_NEW_ADDRESS:
            return {...state, address: action.payload};
        case NEW_ADDRESS_FOUND:
            return {...state, addressFound: action.payload};
        case CHANGE_NEW_DESCRIPTION:
            return {...state, description: action.payload};
        case CHANGE_NEW_LATITUDE:
            return {...state, latitude: action.payload};
        case CHANGE_NEW_LONGITUDE:
            return {...state, longitude: action.payload};
        case CHANGE_NEW_CITY:
            return {...state, city: action.payload};
        case CHANGE_NEW_PROVINCE:
            return {...state, province: action.payload};
        case CHANGE_NEW_COUNTRY:
            return {...state, country: action.payload};
        case CHANGE_NEW_POSTAL_CODE:
            return {...state, postal_code: action.payload};
        case CHANGE_NEW_PRICE:
            if (action.payload < 0) {
                return {...state};
            }
            return {...state, price: Math.round(action.payload)};
        case ADD_NEW_SECTOR:
            return {...state, sectors: [...state.sectors, action.payload]};
        case REMOVE_NEW_SECTOR:
            return {...state, sectors: state.sectors.filter(item => item !== action.payload)};
        case ADD_NEW_CATEGORY:
            return {...state, categories: [...state.categories, action.payload]};
        case REMOVE_NEW_CATEGORY:
            return {...state, categories: state.categories.filter(item => item !== action.payload)};
        case ADD_NEW_FEATURE:
            return {...state, features: [...state.features, action.payload]};
        case REMOVE_NEW_FEATURE:
            return {...state, features: state.features.filter(item => item !== action.payload)};
        case ADD_NEW_ZONE:
            let newZone = cleanString(action.payload);
            return {...state, zones: [...state.zones, newZone]};
        case REMOVE_NEW_ZONE:
            return {...state, zones: state.zones.filter(item => item !== action.payload)};
        case ADD_NEW_TAG:
            let newTag = cleanString(action.payload);
            return {...state, tags: [...state.tags, newTag]};
        case REMOVE_NEW_TAG:
            return {...state, tags: state.tags.filter(item => item !== action.payload)};
        case ADD_NEW_OPENING_INTERVAL:
            return {...state, opening_intervals: [...state.opening_intervals, action.payload]};
        case REMOVE_NEW_OPENING_INTERVAL:
            return {...state, opening_intervals: state.opening_intervals.filter(item => item !== action.payload)};
        case ADD_NEW_HAPPY_INTERVAL:
            return {...state, happy_intervals: [...state.happy_intervals, action.payload]};
        case REMOVE_NEW_HAPPY_INTERVAL:
            return {...state, happy_intervals: state.happy_intervals.filter(item => item !== action.payload)};
        case CHANGE_NEW_LOGO_IMAGE:
            return {...state, newLogoImage: action.payload};
        case ADD_NEW_FACADE_IMAGE:
            return {...state, newFacadeImage: [...state.newFacadeImage, action.payload]};
        case REMOVE_NEW_FACADE_IMAGE:
            return {...state, newFacadeImage: state.newFacadeImage.filter(item => item !== action.payload)};
        case ADD_NEW_TABLES_IMAGE:
            return {...state, newTablesImage: [...state.newTablesImage, action.payload]};
        case REMOVE_NEW_TABLES_IMAGE:
            return {...state, newTablesImage: state.newTablesImage.filter(item => item !== action.payload)};

        case CHANGE_NEW_USERNAME:
            return {...state, newUserName: action.payload};
        case CHANGE_NEW_EMAIL:
            return {...state, newUserEmail: action.payload};
        case CHANGE_NEW_PASSWORD:
            return {...state, newUserPassword: action.payload};

        case ADD_SHIFT:
            return {...state, shifts: [...state.shifts, action.payload]};
        case REMOVE_SHIFT:
            return {...state, shifts: state.shifts.filter(item => item !== action.payload)};
        case CHANGE_BOOKING_NOTIFICATION:
            return {...state, bookingNotification: action.payload};
        case CHANGE_SECTORS_VISUALIZATION:
            return {...state, sectorsVisualization: action.payload};
        case CHANGE_ACCEPTANCE_RADIUS:
            return {...state, acceptanceRadius: action.payload};
        case CHANGE_STAYING_RADIUS:
            return {...state, stayingRadius: action.payload};
        case CHANGE_MAX_CAPACITY:
            return {...state, maxCapacity: action.payload};
        case CHANGE_BIG_GROUP:
            return {...state, bigGroup: action.payload};
        case CHANGE_TOLERANCE:
            return {...state, tolerance: action.payload};
        case CHANGE_CLOSE_TIME:
            return {...state, closeTime: action.payload};
        case ADD_NEW_MESAYA_SECTOR:
            return {...state, mesayaSectors: [...state.mesayaSectors, action.payload]};
        case REMOVE_NEW_MESAYA_SECTOR:
            return {...state, mesayaSectors: state.mesayaSectors.filter(item => item !== action.payload)};

        case CHANGE_AUTO_ACCEPTANCE_PERCENTAGE:
            return {...state, autoAcceptancePercentage: action.payload};

        case SWITCH_GOOGLE_PLACES_DIALOG:
            return {...state, googlePlacesDialog: action.payload};

        case TOGGLE_LOADING_GOOGLE_PLACES:
            return {...state, loadingPlace: !state.loadingPlace}

        case TOGGLE_LOADING:
            return {...state, loading: !state.loading}

        case SWITCH_BOOKING_NOTIFICATION_ENABLE:
            return {...state, bookingNotificationEnabled: !state.bookingNotificationEnabled};

        case CHANGE_INFORMATION_TITLE:
            return {...state, informationTitle: action.payload};
        case CHANGE_INFORMATION_DESCRIPTION:
            return {...state, informationDescription: action.payload};

        case CHANGE_BILLING_INFO:
            return {...state, [action.payload.name]: action.payload.value};

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default createReducer;
