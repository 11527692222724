import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, } from '@material-ui/core';
import { changeNewUserName, changeNewUserEmail, changeNewUserPassword, } from '../../actions';

import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        marginTop: 20,
    },
    title: {
        fontSize: 24,
    },
}));

function UserForm(props) {
    const classes = useStyles();
    const { newUserName, newUserEmail, newUserPassword, changeNewUserName, changeNewUserEmail, changeNewUserPassword, } = props;

    return (
        <Grid container spacing={3} className={classes.mainContainer}>
            <Grid item xs={12} className={classes.title}>Usuario administrador</Grid>
            <Grid item xs={12} md={4}>
                <TextField value={newUserName} onChange={e => changeNewUserName(e.target.value)} margin="dense" id="userName" label="Nombre de usuario" type="text" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField value={newUserEmail} onChange={e => changeNewUserEmail(e.target.value)} margin="dense" id="userEmail" label="Email" type="email" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField value={newUserPassword} onChange={e => changeNewUserPassword(e.target.value)} margin="dense" id="userPassword" label="Contraseña" type="password" fullWidth />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        newUserName: state.partnerReducer.createReducer.newUserName,
        newUserEmail: state.partnerReducer.createReducer.newUserEmail,
        newUserPassword: state.partnerReducer.createReducer.newUserPassword,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeNewUserName: (data) => dispatch(changeNewUserName(data)),
        changeNewUserEmail: (data) => dispatch(changeNewUserEmail(data)),
        changeNewUserPassword: (data) => dispatch(changeNewUserPassword(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);