import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, TextField, Button} from '@material-ui/core';
import GooglePlacesDialog from '../GooglePlacesDialog';

import {
    changeNewName,
    changeNewAddress,
    changeNewDescription,
    changeNewLatitude,
    changeNewLongitude,
    changeNewCity,
    changeNewProvince,
    changeNewCountry,
    changeNewPostalCode,
    changeNewPrice,
    addNewCategory,
    removeNewCategory,
    addNewFeature,
    removeNewFeature,
    addNewZone,
    removeNewZone,
    addNewTag,
    removeNewTag,
    addNewOpeningInterval,
    removeNewOpeningInterval,
    addNewSector,
    removeNewSector,
    searchInGooglePlacesByAddress,
    changeNewId,
    changeNewScore,
    switchGooglePlacesDialog,
    changeInformationTitle,
    changeInformationDescription,
    addNewHappyInterval,
    removeNewHappyInterval,
} from '../../actions';

import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    title: {
        fontSize: 24,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    timeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    timeTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

function GeneralInfoForm(props) {
    const classes = useStyles();
    const {
        id,
        score,
        name,
        address,
        latitude,
        longitude,
        price,
        changeNewName,
        changeNewAddress,
        changeNewLatitude,
        changeNewLongitude,
        changeNewPrice,
        switchGooglePlacesDialog,
    } = props;

    return (
        <Grid className={classes.mainContainer} container justify="center" spacing={3}>
            <GooglePlacesDialog/>
            <Grid item xs={12} className={classes.title}>Información general</Grid>
            <Grid item xs={12} md={3}>
                <TextField value={name} onChange={e => changeNewName(e.target.value)} autoFocus margin="dense" id="name"
                           label="Nombre" type="text" fullWidth/>
            </Grid>
            <Grid item xs={6} md={1}>
                <TextField value={latitude} onChange={e => changeNewLatitude(e.target.value)} margin="dense"
                           id="latitude" label="Latitud" type="text" fullWidth/>
            </Grid>
            <Grid item xs={6} md={1}>
                <TextField value={longitude} onChange={e => changeNewLongitude(e.target.value)} margin="dense"
                           id="longitude" label="Longitud" type="text" fullWidth/>
            </Grid>

            <Grid item xs={12} md={1}>
                <TextField value={price} onChange={e => changeNewPrice(e.target.value)} margin="dense" id="price"
                           label="Precio" type="number" fullWidth/>
            </Grid>
            <Grid item xs={10} md={1}>
                <TextField value={id} margin="dense" id="id" label="ID" type="text" fullWidth disabled/>
            </Grid>
            <Grid item xs={2} md={1}>
                <TextField value={score} margin="dense" id="score" label="Puntaje" type="text" fullWidth disabled/>
            </Grid>
            <Grid item xs={12} md={2}>
                <TextField value={address} onChange={e => changeNewAddress(e.target.value)} margin="dense" id="address"
                           label="Dirección del Google Places" type="text" fullWidth/>
            </Grid>
            <Grid item xs={12} md={2}>
                <Button onClick={() => switchGooglePlacesDialog(true)}>BUSCAR EN PLACES</Button>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {

    return {
        generalSettings: state.firestore.data.generalSettings,
        id: state.partnerReducer.createReducer.id,
        score: state.partnerReducer.createReducer.score,
        name: state.partnerReducer.createReducer.name,
        address: state.partnerReducer.createReducer.address,
        addressFound: state.partnerReducer.createReducer.addressFound,
        description: state.partnerReducer.createReducer.description,
        latitude: state.partnerReducer.createReducer.latitude,
        longitude: state.partnerReducer.createReducer.longitude,
        city: state.partnerReducer.createReducer.city,
        province: state.partnerReducer.createReducer.province,
        country: state.partnerReducer.createReducer.country,
        postal_code: state.partnerReducer.createReducer.postal_code,
        price: state.partnerReducer.createReducer.price,
        sectors: state.partnerReducer.createReducer.sectors,
        categories: state.partnerReducer.createReducer.categories,
        tags: state.partnerReducer.createReducer.tags,
        zones: state.partnerReducer.createReducer.zones,
        features: state.partnerReducer.createReducer.features,
        opening_intervals: state.partnerReducer.createReducer.opening_intervals,
        happy_intervals: state.partnerReducer.createReducer.happy_intervals,
        informationTitle: state.partnerReducer.createReducer.informationTitle,
        informationDescription: state.partnerReducer.createReducer.informationDescription,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeNewId: (data) => dispatch(changeNewId(data)),
        changeNewScore: (data) => dispatch(changeNewScore(data)),
        changeNewName: (data) => dispatch(changeNewName(data)),
        changeNewAddress: (data) => dispatch(changeNewAddress(data)),
        changeNewDescription: (data) => dispatch(changeNewDescription(data)),
        changeNewLatitude: (data) => dispatch(changeNewLatitude(data)),
        changeNewLongitude: (data) => dispatch(changeNewLongitude(data)),
        changeNewCity: (data) => dispatch(changeNewCity(data)),
        changeNewProvince: (data) => dispatch(changeNewProvince(data)),
        changeNewCountry: (data) => dispatch(changeNewCountry(data)),
        changeNewPostalCode: (data) => dispatch(changeNewPostalCode(data)),
        changeNewPrice: (data) => dispatch(changeNewPrice(data)),
        addNewCategory: (data) => dispatch(addNewCategory(data)),
        removeNewCategory: (data) => dispatch(removeNewCategory(data)),
        addNewFeature: (data) => dispatch(addNewFeature(data)),
        removeNewFeature: (data) => dispatch(removeNewFeature(data)),
        addNewZone: (data) => dispatch(addNewZone(data)),
        removeNewZone: (data) => dispatch(removeNewZone(data)),
        addNewTag: (data) => dispatch(addNewTag(data)),
        removeNewTag: (data) => dispatch(removeNewTag(data)),
        addNewSector: (data) => dispatch(addNewSector(data)),
        removeNewSector: (data) => dispatch(removeNewSector(data)),
        searchInGooglePlacesByAddress: () => dispatch(searchInGooglePlacesByAddress()),
        switchGooglePlacesDialog: (data) => dispatch(switchGooglePlacesDialog(data)),

        changeInformationTitle: (data) => dispatch(changeInformationTitle(data)),
        changeInformationDescription: (data) => dispatch(changeInformationDescription(data)),

        addNewOpeningInterval: (data) => dispatch(addNewOpeningInterval(data)),
        removeNewOpeningInterval: (data) => dispatch(removeNewOpeningInterval(data)),
        addNewHappyInterval: (data) => dispatch(addNewHappyInterval(data)),
        removeNewHappyInterval: (data) => dispatch(removeNewHappyInterval(data)),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {

        return [{
            collection: 'generalSettings',
        },];
    })
)(GeneralInfoForm);