import React, {useState,} from 'react';
import axios from 'axios';
import {
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    LinearProgress,
} from '@material-ui/core';

import {switchGooglePlacesDialog, selectGooglePlaceResult, toggleLoadingPlaces,} from '../../actions';

import {connect} from 'react-redux';

function GooglePlacesDialog(props) {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const {
        googlePlacesDialog,
        loadingPlace,
        switchGooglePlacesDialog,
        selectGooglePlaceResult,
        toggleLoadingPlaces,
    } = props;

    const searchOnPlaces = async () => {
        toggleLoadingPlaces();
        const response = await axios.get("https://us-central1-filas-1ebb7.cloudfunctions.net/googlePlacesQuery", {
            headers: {'Access-Control-Allow-Origin': '*'},
            params: {address: search}
        });
        toggleLoadingPlaces();
        setResults(response.data);

    }

    console.log("Loading ", loadingPlace)

    return (
        <Dialog
            open={googlePlacesDialog}
            onClose={() => switchGooglePlacesDialog(false)}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"Buscar en Google Places"}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <TextField value={search} onChange={e => setSearch(e.target.value)} margin="dense" id="search"
                                   label="Buscar en Google Places" type="text" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        {loadingPlace ? <LinearProgress /> : <List component="nav" aria-label="Resultados">
                            {
                                results
                                    ? results.map(result => <ListItem button
                                                                      onClick={() => selectGooglePlaceResult(result)}
                                                                      key={result.place_id}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={result.name} secondary={result.formatted_address}/>
                                    </ListItem>)
                                    : null
                            }

                        </List>}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button onClick={searchOnPlaces}>Buscar</Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => switchGooglePlacesDialog(false)} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => {

    return {
        googlePlacesDialog: state.partnerReducer.createReducer.googlePlacesDialog,
        loadingPlace: state.partnerReducer.createReducer.loadingPlace,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        switchGooglePlacesDialog: (data) => dispatch(switchGooglePlacesDialog(data)),
        selectGooglePlaceResult: (data) => dispatch(selectGooglePlaceResult(data)),
        toggleLoadingPlaces: () => dispatch(toggleLoadingPlaces()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GooglePlacesDialog);