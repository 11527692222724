import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, AppBar, Toolbar, Button, } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { connect } from 'react-redux';
import { signOut, } from '../../actions/authActions';
import NewPartnerForm from '../../components/NewPartnerForm';


const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 56,
        background: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

function HomePage(props) {
    const classes = useStyles();
    const { signOut, } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <Button color="inherit" onClick={signOut}><ExitToAppIcon /></Button>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item xs={12}>
                <NewPartnerForm />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);