import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import {createError, createPartner,} from '../../actions';
import GeneralInfoForm from '../GeneralInfoForm';
import UserForm from '../UserForm';
import SubscriptionPlan from "../SubscriptionPlan";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
    },
    createButton: {
        marginTop: 24,
        background: 'grey',
        color: 'white',
    }
}));

function NewPartnerForm(props) {
    const classes = useStyles();
    const { createPartner, loading, error, closeError } = props;

    return (
        <Grid className={classes.mainContainer} container justify="center">
            <Dialog
                open={error !== undefined && error !== ''}
                onClose={() => closeError()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Error!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeError()} autoFocus>
                        OK!
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <GeneralInfoForm />
            </Grid>
            <Grid item xs={12}>
                <UserForm />
            </Grid>
            <Grid item xs={12}>
                <SubscriptionPlan />
            </Grid>
            <Grid item xs={12}>
                <Button className={classes.createButton} onClick={createPartner}>Crear</Button>
            </Grid>
            {loading ? <CircularProgress /> : null}
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.partnerReducer.createReducer.loading,
        error: state.partnerReducer.createReducer.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createPartner: () => dispatch(createPartner()),
        closeError: (e) => dispatch(createError(e)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPartnerForm);
