import React, {useEffect} from 'react'
import {FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {changeBillingInfo} from "../../actions";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    title: {
        fontSize: 24,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    timeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    timeTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const SubscriptionPlan = (props) => {
    const classes = useStyles();

    const {
        name,
        last_name,
        businessName,
        fantasyName,
        email,
        address,
        availableCountries,
        city,
        country,
        cuit,
        legal,
        phone,
        zip_code,
        plans,
        plan_billing,
    } = props;

    const {changeBillingInfo} = props;

    useEffect(() => {
        if (plans?.length > 0) {
            changeBillingInfo({
                name: 'plan_billing',
                value: plans[0].id,
            });
        }
    }, [plans]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        changeBillingInfo({
            name,
            value
        });
    }

    return (
        <Grid className={classes.mainContainer} container justify='center' spacing={3} direction={'column'} >
            <Grid item xs={12} className={classes.title}>Facturacion</Grid>
            <Grid container item xs={12} spacing={3} justify={'center'} >
                <Grid item xs={2}>
                    <FormLabel id="demo-radio-buttons-group-label">TIPO DE PERSONA</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="fisica"
                        value={legal}
                        name="legal_billing"

                        onChange={handleChange}
                    >
                        <FormControlLabel value={"fisica"} control={<Radio/>} label="Fisica"/>
                        <FormControlLabel value={"juridica"} control={<Radio/>} label="Juridica"/>
                    </RadioGroup>
                </Grid>
                {legal === "fisica"
                    ?
                    <Grid container item xs={4}>
                        <Grid item xs={5} style={{marginRight: '50px'}}>
                            <TextField
                                value={name} onChange={handleChange} name={'name_billing'} label={'Nombre'}
                                margin="dense"
                                fullWidth
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                value={last_name} onChange={handleChange} name={'last_name_billing'} label={'Apellido'}
                                margin="dense" fullWidth type="text"
                            />
                        </Grid>
                    </Grid>
                    : <Grid item xs={2}>
                        <TextField
                            value={fantasyName} onChange={handleChange} name={'fantasyName_billing'}
                            label={'Razon social'}
                            margin="dense" fullWidth type="text"
                        />
                    </Grid>}

            </Grid>
            <Grid container item xs={12} spacing={3} justify={'center'}>
                <Grid item xs={2}>
                    <TextField
                        value={address} onChange={handleChange} name={'address_billing'} label={'Dirección'}
                        margin="dense"
                        fullWidth type="text"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={city} onChange={handleChange} name={'city_billing'} label={'Ciudad'} margin="dense"
                        fullWidth
                        type="text"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={zip_code} onChange={handleChange} name={'zip_code_billing'} label={'Codigo Postal'}
                        margin="dense" fullWidth type="text"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3} justify={'center'}>
                <Grid item xs={2}>
                    <TextField
                        value={phone} onChange={handleChange} name={'phone_billing'} label={'Telefono'} margin="dense"
                        fullWidth type="text"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={email} onChange={handleChange} name={'email_billing'} label={'Email'} margin="dense"
                        fullWidth type="text"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={cuit} onChange={handleChange} name={'cuit_billing'} label={'DNI - CUIT/CUIL'}
                        margin="dense"
                        fullWidth type="text"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <Grid item xs={2} style={{marginRight: '50px'}}>
                    <TextField
                        value={businessName} onChange={handleChange} name={'businessName_billing'}
                        label={'Nombre del establecimiento'} margin="dense" fullWidth type="text"
                    />
                </Grid>


                <Grid item xs={2}>
                    <FormLabel id="select-for-plan">Plan</FormLabel>
                    <Select value={plan_billing} label="Plan" fullWidth name={'plan_billing'} onChange={handleChange}
                            margin="dense" aria-labelledby='select-for-plan'>
                        {plans?.map((plan, index) => {
                            return (
                                <MenuItem key={index} value={plan.id}>{plan.name}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        name: state.partnerReducer.createReducer.name_billing,
        last_name: state.partnerReducer.createReducer.last_name_billing,
        cuit: state.partnerReducer.createReducer.cuit_billing,
        phone: state.partnerReducer.createReducer.phone_billing,
        country: state.partnerReducer.createReducer.country_billing,
        city: state.partnerReducer.createReducer.city_billing,
        address: state.partnerReducer.createReducer.address_billing,
        zip_code: state.partnerReducer.createReducer.zip_code_billing,
        businessName: state.partnerReducer.createReducer.businessName_billing,
        fantasyName: state.partnerReducer.createReducer.fantasyName_billing,
        legal: state.partnerReducer.createReducer.legal_billing,
        email: state.partnerReducer.createReducer.email_billing,
        plan_billing: state.partnerReducer.createReducer.plan_billing,
        plans: state.firestore.ordered.plans,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeBillingInfo: (data) => dispatch(changeBillingInfo(data))
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect((props) => [
    {collection: 'plans',}
]),)(SubscriptionPlan)
