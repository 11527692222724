import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import authReducer from './authReducer';
import partnerReducer from './partnerReducer';

export default (history) => combineReducers({ 
    router: connectRouter(history),
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    authReducer,
    partnerReducer
});