import {auth} from './setup';

export function watchUserChanges(callback) {
    return auth.onAuthStateChanged((user) => {
        if (user && !user.isAnonymous) {
            callback({
                id: user.uid,
                email: user.email,
                displayName: user.displayName,
            });
        } else {
            callback(null);
        }
    });
}
