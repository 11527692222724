export const LOCAL_DB = false;
export const LOCAL_FUNCTIONS = false;
export const LOCAL_AUTH = false


// Tipos de Environments que existen
export const PRODUCTION = 'prod';
export const DEVELOPMENT = 'dev';
export const TESTING = 'test';

//Environment actual
export const ENVIRONMENT = process.env.REACT_APP_ENVIROMENT || DEVELOPMENT;
