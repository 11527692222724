export const cleanString = (data) => {
    data = data.toLowerCase();
    data = data.replace('ü', 'u');
    return data.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const hhssToMinutes = (data) => {
    let parts = data.split(":");
    if (parts.length !== 2) return;

    let hours = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);

    return (60 * hours) + minutes;
}

export const openCloseTimeToOpenDuration = (open, close) => {
    let duration;
    if (close >= open) {
        duration = close - open;
    } else {
        duration = 1440 - open + close; // 1440 son los minutos en un dia. Se calculan los minutos desde opentime hasta que termine el dia y luego se le suma lo que resta para que cierre.
    }

    return duration;
}