import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, } from '@material-ui/core';
import { connect } from 'react-redux';
import Signin from '../../components/Forms/Signin';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        height: window.innerHeight + 'px',
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(to right, #4DB09E, #34766A) no-repeat 0 0 / cover',
        height: window.innerHeight + 'px',
    },
    textField: {
        width: '100%',
        margin: '10px',
    },
    welcomeTitle: {
        color: '#fff',
        marginBottom: '50px',
    },
    signUpButton: {
        color: '#fff',
        borderRadius: '20px',
        border: 'solid 1px #fff',
        background: 'rgba(0, 0, 0, 0)',
        height: '40px',
        width: '50%',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)',
        },
    },
}));

function LoginPage(props) {
    const classes = useStyles();

    return (
        <Grid className={classes.mainContainer} container justify="center" alignItems="center">
            <Grid className={classes.formContainer} item xs={12} md={6}>
            <Signin />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);