import {
    SWITCH_SIGN_FORM,
    SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNOUT_SUCCESS, SIGNOUT_ERROR,
    CHANGE_SIGNIN_EMAIL, CHANGE_SIGNIN_PASSWORD, CHANGE_SIGNIN_ERROR,
    RESET_ALL_DATA,
} from '../../constants';

const initState = {
    currentForm: 0,
    email: '',
    password: '',
    authError: null,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SWITCH_SIGN_FORM:
            return { ...state, currentForm: action.payload };
        case CHANGE_SIGNIN_EMAIL:
            return { ...state, email: action.payload, authError: null };
        case CHANGE_SIGNIN_PASSWORD:
            return { ...state, password: action.payload, authError: null };
        case CHANGE_SIGNIN_ERROR:
            return { ...state, authError: action.payload };
        case SIGNIN_SUCCESS:
            return { ...state, authError: null, email: '', password: '', };
        case SIGNIN_ERROR:
            return { ...state, authError: 'Error al ingresar' };
        case SIGNOUT_SUCCESS:
            return state;
        case SIGNOUT_ERROR:
            return state;

        case RESET_ALL_DATA:
            return initState;
        default:
            return state;
    }
}

export default authReducer;