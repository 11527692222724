export const NEW_PARTNER_SUCCESS = "NEW_PARTNER_SUCCESS";
export const NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION = "NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION";
export const NEW_PARTNER_ERROR = "NEW_PARTNER_ERROR";

export const TOGGLE_LOADING = "TOGGLE_LOADING";

export const NEW_ADDRESS_FOUND = "NEW_ADDRESS_FOUND";

export const CHANGE_NEW_ID = "CHANGE_NEW_ID";
export const CHANGE_NEW_SCORE = "CHANGE_NEW_SCORE";
export const CHANGE_NEW_NAME = "CHANGE_NEW_NAME";
export const CHANGE_NEW_ADDRESS = "CHANGE_NEW_ADDRESS";
export const CHANGE_NEW_DESCRIPTION = "CHANGE_NEW_DESCRIPTION";
export const CHANGE_NEW_LATITUDE = "CHANGE_NEW_LATITUDE";
export const CHANGE_NEW_LONGITUDE = "CHANGE_NEW_LONGITUDE";
export const CHANGE_NEW_CITY = "CHANGE_NEW_CITY";
export const CHANGE_NEW_PROVINCE = "CHANGE_NEW_PROVINCE";
export const CHANGE_NEW_COUNTRY = "CHANGE_NEW_COUNTRY";
export const CHANGE_NEW_POSTAL_CODE = "CHANGE_NEW_POSTAL_CODE";
export const CHANGE_NEW_PRICE = "CHANGE_NEW_PRICE";

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const REMOVE_NEW_CATEGORY = "REMOVE_NEW_CATEGORY";

export const ADD_NEW_FEATURE = "ADD_NEW_FEATURE";
export const REMOVE_NEW_FEATURE = "REMOVE_NEW_FEATURE";

export const ADD_NEW_SECTOR = "ADD_NEW_SECTOR";
export const REMOVE_NEW_SECTOR = "REMOVE_NEW_SECTOR";

export const ADD_NEW_ZONE = "ADD_NEW_ZONE";
export const REMOVE_NEW_ZONE = "REMOVE_NEW_ZONE";

export const ADD_NEW_TAG = "ADD_NEW_TAG";
export const REMOVE_NEW_TAG = "REMOVE_NEW_TAG";

export const ADD_NEW_OPENING_INTERVAL = "ADD_NEW_OPENING_INTERVAL";
export const REMOVE_NEW_OPENING_INTERVAL = "REMOVE_NEW_OPENING_INTERVAL";

export const CHANGE_NEW_LOGO_IMAGE = "CHANGE_NEW_LOGO_IMAGE";
export const CHANGE_NEW_FACADE_IMAGE = "CHANGE_NEW_FACADE_IMAGE";
export const CHANGE_NEW_TABLES_IMAGE = "CHANGE_NEW_TABLES_IMAGE";

export const CHANGE_NEW_USERNAME = "CHANGE_NEW_USERNAME";
export const CHANGE_NEW_EMAIL = "CHANGE_NEW_EMAIL";
export const CHANGE_NEW_PASSWORD = "CHANGE_NEW_PASSWORD";

export const ADD_SHIFT = "ADD_SHIFT";
export const REMOVE_SHIFT = "REMOVE_SHIFT";

export const CHANGE_ACCEPTANCE_RADIUS = "CHANGE_ACCEPTANCE_RADIUS";
export const CHANGE_STAYING_RADIUS = "CHANGE_STAYING_RADIUS";
export const CHANGE_MAX_CAPACITY = "CHANGE_MAX_CAPACITY";
export const CHANGE_BIG_GROUP = "CHANGE_BIG_GROUP";
export const CHANGE_TOLERANCE = "CHANGE_TOLERANCE";
export const CHANGE_CLOSE_TIME = "CHANGE_CLOSE_TIME";

export const CHANGE_AUTO_ACCEPTANCE_PERCENTAGE = "CHANGE_AUTO_ACCEPTANCE_PERCENTAGE";
export const CHANGE_BOOKING_NOTIFICATION = "CHANGE_BOOKING_NOTIFICATION";
export const CHANGE_SECTORS_VISUALIZATION = "CHANGE_SECTORS_VISUALIZATION";

export const ADD_NEW_MESAYA_SECTOR = "ADD_NEW_MESAYA_SECTOR";
export const REMOVE_NEW_MESAYA_SECTOR = "REMOVE_NEW_MESAYA_SECTOR";

export const SWITCH_GOOGLE_PLACES_DIALOG = "SWITCH_GOOGLE_PLACES_DIALOG";
export const TOGGLE_LOADING_GOOGLE_PLACES = "TOGGLE_LOADING_GOOGLE_PLACES";

export const SWITCH_BOOKING_NOTIFICATION_ENABLE = "SWITCH_BOOKING_NOTIFICATION_ENABLE";

export const CHANGE_INFORMATION_TITLE = "CHANGE_INFORMATION_TITLE";
export const CHANGE_INFORMATION_DESCRIPTION = "CHANGE_INFORMATION_DESCRIPTION";

export const ADD_NEW_HAPPY_INTERVAL = "ADD_NEW_HAPPY_INTERVAL";
export const REMOVE_NEW_HAPPY_INTERVAL = "REMOVE_NEW_HAPPY_INTERVAL";

export const ADD_NEW_TABLES_IMAGE = "ADD_NEW_TABLES_IMAGE";
export const REMOVE_NEW_TABLES_IMAGE = "REMOVE_NEW_TABLES_IMAGE";
export const ADD_NEW_FACADE_IMAGE = "ADD_NEW_FACADE_IMAGE";
export const REMOVE_NEW_FACADE_IMAGE = "REMOVE_NEW_FACADE_IMAGE";

export const CHANGE_BILLING_INFO = "CHANGE_BILLING_INFO";
