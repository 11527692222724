import React from "react";
import { Route, Redirect, } from "react-router-dom";
import { signOut, } from '../../actions';
import { connect } from 'react-redux';

function GuardRoute(props) {
    const { type, signOut, ...rest } = props;
    if ((type === 'private' && !props.auth.uid) || (props.auth && props.auth.email && props.auth.email !== "admin@partners.com")) {
        if((props.auth && props.auth.email && props.auth.email !== "admin@partners.com")) {
            signOut();
        }
        return <Redirect to='/login' />
    } else if (type === 'public' && props.auth.uid) {
        return <Redirect to='/' />
    }
    return (
        <Route {...rest} />
    );
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardRoute);