import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore
import 'firebase/analytics';
import 'firebase/functions';
import {LOCAL_AUTH, LOCAL_DB, LOCAL_FUNCTIONS} from "../../constants/config";   // for cloud firestore

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


if (LOCAL_FUNCTIONS) {
    firebase.app().functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION).useEmulator("localhost", 5001);
}

firebase.analytics();
if (window.location.hostname === "localhost" && LOCAL_DB) {
    firebase.firestore().settings({
        host: "localhost:8080",
        ssl: false,
    });
} else {
    firebase.firestore();
}

export const auth = firebase.auth();
if (LOCAL_AUTH) {
    auth.useEmulator('http://localhost:9099')
}
export const db = firebase.firestore();

export default firebase;
