import {
    SWITCH_SIGN_FORM,
    SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNOUT_SUCCESS, SIGNOUT_ERROR,
    CHANGE_SIGNIN_EMAIL, CHANGE_SIGNIN_PASSWORD, CHANGE_SIGNIN_ERROR,
} from '../../constants';
import 'firebase/auth';

export const switchSignForm = (form_index) => ({
    type: SWITCH_SIGN_FORM,
    payload: form_index
});
export const changeSigninEmail = (email) => ({
    type: CHANGE_SIGNIN_EMAIL,
    payload: email
});
export const changeSigninPassword = (password) => ({
    type: CHANGE_SIGNIN_PASSWORD,
    payload: password
});
export const changeSigninError = (error) => ({
    type: CHANGE_SIGNIN_ERROR,
    payload: error
});
//-----------------------------------------------------------------------------------------------
export const signinSuccess = () => ({
    type: SIGNIN_SUCCESS,
});
export const signinError = (error) => ({
    type: SIGNIN_ERROR,
    error: error
});
export const signIn = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const { authReducer } = getState();
        const { email, password } = authReducer;

        try {
            const signInResp = await firebase.auth().signInWithEmailAndPassword(email, password);
            const getUserResp = await firestore.collection('users').doc(signInResp.user.uid).get();
            const data = getUserResp.data();
            if (!data.type || (data.type !== 'admin' && data.type !== 'shopAdmin')) {
                dispatch(signOut());
            }

            dispatch(signinSuccess());
        } catch (error) {
            dispatch(signinError(error));
        }
    }
}
//-----------------------------------------------------------------------------------------------
export const signOutSuccess = () => ({
    type: SIGNOUT_SUCCESS,
});
export const signOutError = (error) => ({
    type: SIGNOUT_ERROR,
    error: error
});

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch(signOutSuccess());
        }).catch((error) => {
            dispatch(signOutError(error));
        });
    }
}