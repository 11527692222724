import axios from 'axios';
import {hhssToMinutes, openCloseTimeToOpenDuration,} from '../../utils/formatters';
import {
    ADD_NEW_CATEGORY,
    ADD_NEW_FACADE_IMAGE,
    ADD_NEW_FEATURE,
    ADD_NEW_HAPPY_INTERVAL,
    ADD_NEW_MESAYA_SECTOR,
    ADD_NEW_OPENING_INTERVAL,
    ADD_NEW_SECTOR,
    ADD_NEW_TABLES_IMAGE,
    ADD_NEW_TAG,
    ADD_NEW_ZONE,
    ADD_SHIFT,
    CHANGE_ACCEPTANCE_RADIUS,
    CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    CHANGE_BIG_GROUP,
    CHANGE_BILLING_INFO,
    CHANGE_BOOKING_NOTIFICATION,
    CHANGE_CLOSE_TIME,
    CHANGE_INFORMATION_DESCRIPTION,
    CHANGE_INFORMATION_TITLE,
    CHANGE_MAX_CAPACITY,
    CHANGE_NEW_ADDRESS,
    CHANGE_NEW_CITY,
    CHANGE_NEW_COUNTRY,
    CHANGE_NEW_DESCRIPTION,
    CHANGE_NEW_EMAIL,
    CHANGE_NEW_FACADE_IMAGE,
    CHANGE_NEW_ID,
    CHANGE_NEW_LATITUDE,
    CHANGE_NEW_LOGO_IMAGE,
    CHANGE_NEW_LONGITUDE,
    CHANGE_NEW_NAME,
    CHANGE_NEW_PASSWORD,
    CHANGE_NEW_POSTAL_CODE,
    CHANGE_NEW_PRICE,
    CHANGE_NEW_PROVINCE,
    CHANGE_NEW_SCORE,
    CHANGE_NEW_TABLES_IMAGE,
    CHANGE_NEW_USERNAME,
    CHANGE_SECTORS_VISUALIZATION,
    CHANGE_STAYING_RADIUS,
    CHANGE_TOLERANCE,
    NEW_ADDRESS_FOUND,
    NEW_PARTNER_ERROR,
    NEW_PARTNER_SUCCESS,
    NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION,
    PARTNERS,
    REMOVE_NEW_CATEGORY,
    REMOVE_NEW_FACADE_IMAGE,
    REMOVE_NEW_FEATURE,
    REMOVE_NEW_HAPPY_INTERVAL,
    REMOVE_NEW_MESAYA_SECTOR,
    REMOVE_NEW_OPENING_INTERVAL,
    REMOVE_NEW_SECTOR,
    REMOVE_NEW_TABLES_IMAGE,
    REMOVE_NEW_TAG,
    REMOVE_NEW_ZONE,
    REMOVE_SHIFT,
    SWITCH_BOOKING_NOTIFICATION_ENABLE,
    SWITCH_GOOGLE_PLACES_DIALOG,
    TOGGLE_LOADING,
    TOGGLE_LOADING_GOOGLE_PLACES,
    USERS_PARTNERS
} from '../../constants';

import firebase from 'firebase/app'

import 'firebase/auth';
import {firebaseConfig} from '../../services/firebase';
import {clean} from "../../utils/aux_object";
import {LOCAL_AUTH} from "../../constants/config";

export const changeNewId = (data) => ({
    type: CHANGE_NEW_ID,
    payload: data,
});
export const changeNewScore = (data) => ({
    type: CHANGE_NEW_SCORE,
    payload: data,
});

export const changeNewName = (data) => ({
    type: CHANGE_NEW_NAME,
    payload: data,
});

export const changeNewAddress = (data) => ({
    type: CHANGE_NEW_ADDRESS,
    payload: data,
});
export const changeNewAddressFound = (data) => ({
    type: NEW_ADDRESS_FOUND,
    payload: data,
});

export const changeNewDescription = (data) => ({
    type: CHANGE_NEW_DESCRIPTION,
    payload: data,
});
export const changeNewLatitude = (data) => ({
    type: CHANGE_NEW_LATITUDE,
    payload: data,
});
export const changeNewLongitude = (data) => ({
    type: CHANGE_NEW_LONGITUDE,
    payload: data,
});
export const changeNewCity = (data) => ({
    type: CHANGE_NEW_CITY,
    payload: data,
});
export const changeNewProvince = (data) => ({
    type: CHANGE_NEW_PROVINCE,
    payload: data,
});
export const changeNewCountry = (data) => ({
    type: CHANGE_NEW_COUNTRY,
    payload: data,
});
export const changeNewPostalCode = (data) => ({
    type: CHANGE_NEW_POSTAL_CODE,
    payload: data,
});
export const changeNewPrice = (data) => ({
    type: CHANGE_NEW_PRICE,
    payload: data,
});
export const addNewCategory = (data) => ({
    type: ADD_NEW_CATEGORY,
    payload: data,
});
export const removeNewCategory = (data) => ({
    type: REMOVE_NEW_CATEGORY,
    payload: data,
});
export const addNewFeature = (data) => ({
    type: ADD_NEW_FEATURE,
    payload: data,
});
export const removeNewFeature = (data) => ({
    type: REMOVE_NEW_FEATURE,
    payload: data,
});
export const addNewZone = (data) => ({
    type: ADD_NEW_ZONE,
    payload: data,
});
export const removeNewZone = (data) => ({
    type: REMOVE_NEW_ZONE,
    payload: data,
});
export const addNewTag = (data) => ({
    type: ADD_NEW_TAG,
    payload: data,
});
export const removeNewTag = (data) => ({
    type: REMOVE_NEW_TAG,
    payload: data,
});
export const addNewOpeningInterval = (data) => ({
    type: ADD_NEW_OPENING_INTERVAL,
    payload: data,
});
export const removeNewOpeningInterval = (data) => ({
    type: REMOVE_NEW_OPENING_INTERVAL,
    payload: data,
});
export const addNewSector = (data) => ({
    type: ADD_NEW_SECTOR,
    payload: data,
});
export const removeNewSector = (data) => ({
    type: REMOVE_NEW_SECTOR,
    payload: data,
});
export const changeNewLogoImage = (data) => ({
    type: CHANGE_NEW_LOGO_IMAGE,
    payload: data,
});
export const changeNewFacadeImage = (data) => ({
    type: CHANGE_NEW_FACADE_IMAGE,
    payload: data,
});
export const changeNewTablesImage = (data) => ({
    type: CHANGE_NEW_TABLES_IMAGE,
    payload: data,
});
export const changeNewUserName = (data) => ({
    type: CHANGE_NEW_USERNAME,
    payload: data,
});
export const changeNewUserEmail = (data) => ({
    type: CHANGE_NEW_EMAIL,
    payload: data,
});
export const changeNewUserPassword = (data) => ({
    type: CHANGE_NEW_PASSWORD,
    payload: data,
});

export const addShift = (data) => ({
    type: ADD_SHIFT,
    payload: data,
});
export const removeShift = (data) => ({
    type: REMOVE_SHIFT,
    payload: data,
});
export const changeAutoAcceptancePercentage = (data) => ({
    type: CHANGE_AUTO_ACCEPTANCE_PERCENTAGE,
    payload: data,
});
export const changeBookingNotification = (data) => ({
    type: CHANGE_BOOKING_NOTIFICATION,
    payload: data,
});

export const changeAcceptanceRadius = (data) => ({
    type: CHANGE_ACCEPTANCE_RADIUS,
    payload: data,
});
export const changeStayingRadius = (data) => ({
    type: CHANGE_STAYING_RADIUS,
    payload: data,
});
export const changeMaxCapacity = (data) => ({
    type: CHANGE_MAX_CAPACITY,
    payload: data,
});
export const changeBigGroup = (data) => ({
    type: CHANGE_BIG_GROUP,
    payload: data,
});
export const changeTolerance = (data) => ({
    type: CHANGE_TOLERANCE,
    payload: data,
});
export const changeCloseTime = (data) => ({
    type: CHANGE_CLOSE_TIME,
    payload: data,
});
export const setSectorsVisualization = (data) => ({
    type: CHANGE_SECTORS_VISUALIZATION,
    payload: data,
});
export const addMesayaSector = (data) => ({
    type: ADD_NEW_MESAYA_SECTOR,
    payload: data,
});
export const removeMesayaSector = (data) => ({
    type: REMOVE_NEW_MESAYA_SECTOR,
    payload: data,
});
export const switchGooglePlacesDialog = (data) => ({
    type: SWITCH_GOOGLE_PLACES_DIALOG,
    payload: data,
});

export const toggleLoadingPlaces = () => ({
    type: TOGGLE_LOADING_GOOGLE_PLACES,
})

export const toggleLoading = () => ({
    type: TOGGLE_LOADING,
})

export const switchBookingNotificationEnable = () => ({
    type: SWITCH_BOOKING_NOTIFICATION_ENABLE,
});

export const changeInformationTitle = (data) => ({
    type: CHANGE_INFORMATION_TITLE,
    payload: data,
});
export const changeInformationDescription = (data) => ({
    type: CHANGE_INFORMATION_DESCRIPTION,
    payload: data,
});
export const addNewHappyInterval = (data) => ({
    type: ADD_NEW_HAPPY_INTERVAL,
    payload: data,
});
export const removeNewHappyInterval = (data) => ({
    type: REMOVE_NEW_HAPPY_INTERVAL,
    payload: data,
});
export const addNewTablesImage = (data) => ({
    type: ADD_NEW_TABLES_IMAGE,
    payload: data,
});
export const removeNewTablesImage = (data) => ({
    type: REMOVE_NEW_TABLES_IMAGE,
    payload: data,
});
export const addNewFacadeImage = (data) => ({
    type: ADD_NEW_FACADE_IMAGE,
    payload: data,
});
export const removeNewFacadeImage = (data) => ({
    type: REMOVE_NEW_FACADE_IMAGE,
    payload: data,
});


export const changeBillingInfo = (data) => ({
    type: CHANGE_BILLING_INFO,
    payload: data,
});

export const selectGooglePlaceResult = (result) => {
    return async (dispatch, getState) => {
        let allData = result['place_id'] && result['geometry']['location']['lat'] && result['geometry']['location']['lng'];

        if (allData) {
            dispatch(changeNewAddressFound(true));
            dispatch(changeNewId(result['place_id']));
            dispatch(changeNewLatitude(result['geometry']['location']['lat']));
            dispatch(changeNewLongitude(result['geometry']['location']['lng']));
            result['formatted_address'] ? dispatch(changeNewAddress(result['formatted_address'])) : dispatch(changeNewAddress(''));
            result['price_level'] ? dispatch(changeNewPrice(result['price_level'])) : dispatch(changeNewPrice(0));
            result['rating'] ? dispatch(changeNewScore(result['rating'])) : dispatch(changeNewScore(0));

            dispatch(switchGooglePlacesDialog(false));
        } else {
            dispatch(changeNewAddressFound(false));
            dispatch(changeNewId(''));
            dispatch(changeNewPrice(0));
            dispatch(changeNewLatitude(0));
            dispatch(changeNewLongitude(0));
            dispatch(changeNewScore(0));
        }

    }
}

export const searchInGooglePlacesByAddress = () => {
    return async (dispatch, getState) => {
        const {partnerReducer} = getState();
        const {createReducer} = partnerReducer;
        const {address,} = createReducer;

        const response = await axios.get("https://us-central1-filas-1ebb7.cloudfunctions.net/googlePlacesQuery", {
            headers: {'Access-Control-Allow-Origin': '*'},
            params: {address: address}
        });

        let found = response.data && response.data.candidates && response.data.candidates.length === 1;
        let allData = false;
        if (found) {
            let data = response.data.candidates[0];
            allData = data['place_id'] && data['price_level'] && data['geometry']['location']['lat'] && data['geometry']['location']['lng'] && data['rating'];
        }

        if (found && allData) {
            let data = response.data.candidates[0];
            dispatch(changeNewAddressFound(true));
            dispatch(changeNewId(data['place_id']));
            dispatch(changeNewPrice(data['price_level']));
            dispatch(changeNewLatitude(data['geometry']['location']['lat']));
            dispatch(changeNewLongitude(data['geometry']['location']['lng']));
            dispatch(changeNewScore(data['rating']));
        } else {
            dispatch(changeNewAddressFound(false));
            dispatch(changeNewId(''));
            dispatch(changeNewPrice(0));
            dispatch(changeNewLatitude(0));
            dispatch(changeNewLongitude(0));
            dispatch(changeNewScore(0));
        }

    }
}

//---------------------------------------------------------------------------
export const createSuccess = () => ({
    type: NEW_PARTNER_SUCCESS,
});

export const createSuccessWithoutSubscription = () => ({
    type: NEW_PARTNER_SUCCESS_WITHOUT_SUBSCRIPTION,
});


export const createError = (error) => ({
    type: NEW_PARTNER_ERROR,
    payload: error,
});

async function createUser(db, id, {email, pass, name}) {
    // CREACION DEL USUARIO
    let detachedFirebase;
    try {
        detachedFirebase = firebase.app('detachedFirebaseFila');
    } catch (e) {
        detachedFirebase = firebase.initializeApp(firebaseConfig, 'detachedFirebaseFila');
    }

    let detachedAuth = detachedFirebase.auth();
    if (LOCAL_AUTH) {
        detachedAuth.useEmulator('http://localhost:9099');
    }
    const signUpResponse = await detachedAuth.createUserWithEmailAndPassword(email, pass);

    await db.collection(USERS_PARTNERS).doc(signUpResponse.user.uid).set({
        name: name,
        email: email,
        partner: id,
        role: ['admin', 'mesa-ya', 'reserva'],
    });
}

function validateFields(data) {
    const {email_billing, latitude, longitude, plan_billing, name, newUserEmail, newUserPassword, newUserName} = data;

    if (email_billing === undefined || email_billing === '') {
        throw new Error('El email de facturación es obligatorio');
    }
    if (latitude === undefined || latitude === '') {
        throw new Error('La latitud es obligatoria');
    }
    if (longitude === undefined || longitude === '') {
        throw new Error('La longitud es obligatoria');
    }
    if (plan_billing === undefined || plan_billing === '') {
        throw new Error('El plan de facturación es obligatorio');
    }
    if (name === undefined || name === '') {
        throw new Error('El nombre es obligatorio');
    }
    if (newUserEmail === undefined || newUserEmail === '') {
        throw new Error('El email del usuario es obligatorio');
    }
    if (newUserPassword === undefined || newUserPassword === '') {
        throw new Error('La contraseña del usuario es obligatoria');
    }
    if (newUserName === undefined || newUserName === '') {
        throw new Error('El nombre del usuario es obligatorio');
    }
}

async function savePartner(firestore, createReducer) {
    const {
        id,
        name,
        address,
        description,
        latitude,
        longitude,
        city,
        province,
        country,
        postal_code,
        price,
        sectors,
        categories,
        tags,
        features,
        zones,
        opening_intervals,
        happy_intervals,
        newLogoImage,
        newFacadeImage,
        newTablesImage,
        shifts,
        mesayaSectors,
        informationTitle,
        informationDescription,
        score,
        name_billing,
        last_name_billing,
        cuit_billing,
        phone_billing,
        country_billing,
        city_billing,
        address_billing,
        zip_code_billing,
        businessName_billing,
        fantasyName_billing,
        legal_billing,
        email_billing,
    } = createReducer;
    // HORARIOS DE TRABAJO DEL LOCAL
    let openingIntervalsArray = [];
    opening_intervals.forEach(interval => {
        // Se pasa de  closeTime a duration
        let ot = hhssToMinutes(interval['open']); // OPENTIME en minutos
        let ct = hhssToMinutes(interval['close']); // CLOSETIME en minutos
        let duration = openCloseTimeToOpenDuration(ot, ct);

        openingIntervalsArray.push({
            open: ot,
            duration: duration,
            days: interval['days'],
        });
    });

    // HORARIOS DEL HAPPY HOUR
    let happyIntervalsArray = [];
    happy_intervals.forEach(interval => {
        // Se pasa de  closeTime a duration
        let ot = hhssToMinutes(interval['open']); // OPENTIME en minutos
        let ct = hhssToMinutes(interval['close']); // CLOSETIME en minutos
        let duration = openCloseTimeToOpenDuration(ot, ct);

        happyIntervalsArray.push({
            open: ot,
            duration: duration,
            days: interval['days'],
        });
    });

    // CREACION DEL SCHEDULE PARA TURNOS
    let scheduleMatrix = [[], [], [], [], [], [], []];
    shifts.forEach(shift => {
        let realShift = {
            'name': shift.name,
            'opening': hhssToMinutes(shift.opening),
            'advanceStart': hhssToMinutes(shift.advanceStart),
            'advanceEnd': hhssToMinutes(shift.advanceEnd),
            'subShifts': shift.subShifts.map(subshift => ({
                ...subshift,
                'from': hhssToMinutes(subshift.from),
                'to': openCloseTimeToOpenDuration(hhssToMinutes(subshift.from), hhssToMinutes(subshift.to)),
                'sectors': shift.sectors,
            })),
        };
        shift.days.forEach(day => scheduleMatrix[day].push(realShift));
    });
    let schedule = {
        'domingo': scheduleMatrix[0],
        'lunes': scheduleMatrix[1],
        'martes': scheduleMatrix[2],
        'miercoles': scheduleMatrix[3],
        'jueves': scheduleMatrix[4],
        'viernes': scheduleMatrix[5],
        'sabado': scheduleMatrix[6],
    };

    // CREACION DE LOS SECTORES PARA MESAYA
    let MYSectors = {};
    mesayaSectors.forEach(sector => MYSectors[sector] = {full: true, enable: true});


    // CREACION DEL PARTNER
    let data = {
        displayName: name,
        address: address,
        description: description,
        informationTitle: informationTitle,
        informationDescription: informationDescription,
        location: new firebase.firestore.GeoPoint(latitude, longitude),
        city: city,
        province: province,
        country: country,
        zipCode: postal_code,
        price_level: price !== undefined && price !== null && price > 0 ? price : 3,
        tags: tags,
        zones: zones,
        features: features,
        categories: categories,
        score: score,
        sectors: sectors,
        openingIntervals: openingIntervalsArray,
        schedule: schedule,
        acceptanceRadius: 1000,
        stayingRadius: 1500,
        MYmaxCapacity: 12,
        MYbigGroup: 8,
        MYtolerance: 5,
        MYcloseTime: 15,
        MYSectors: MYSectors,
        autoAcceptancePercentage: 80,
        services: ["mesa-ya", "reserva", "take-away"],
        enabledServices: {
            'mesa-ya': true,
            'reservas': true,
            'take-away': false,
        },
        isActive: false,
        shiftsTolerance: 15,
        bookingNotification: 180,
        sectorsVisualization: true,
        allocationMethod: 'mas-vacio',
        timeOfStay: 45,
        wait_time_adoc: 10,
        billing_info: {
            address: address_billing,
            city: city_billing,
            country: country_billing,
            zip_code: zip_code_billing,
            businessName: businessName_billing,
            cuit: cuit_billing,
            email: email_billing,
            fantasyName: fantasyName_billing,
            last_name: last_name_billing,
            legal: legal_billing === "juridica",
            name: name_billing,
            phone: phone_billing,
        }
    };

    if (happyIntervalsArray.length > 0) {
        data['happyIntervals'] = happyIntervalsArray;
    }

    data = clean(data);

    await firestore.collection(PARTNERS).doc(id).set(data);

    try {
        // CREACION DE LAS IMAGENES
        if (newLogoImage) {
            const newImage = new File([newLogoImage], id, {type: newLogoImage.type});
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child('logoImages/' + id + '/' + id);
            const snap = await imageRef.put(newImage);
            const url = await snap.ref.getDownloadURL();
            await firestore.collection(PARTNERS).doc(id).update({
                logoUrl: url,
            });
        }
        if (newFacadeImage) {
            let urls = [];
            for (let i = 0; i < newFacadeImage.length; i++) {
                const newImage = new File([newFacadeImage[i]], newFacadeImage[i].name, {type: newFacadeImage[i].type});
                const storageRef = firebase.storage().ref();
                const imageRef = storageRef.child('facadeImages/' + id + '/' + newFacadeImage[i].name);
                const snap = await imageRef.put(newImage);
                const url = await snap.ref.getDownloadURL();
                urls.push(url);

            }
            await firestore.collection(PARTNERS).doc(id).update({
                facadeImagesUrl: urls,
            });
        }
        if (newTablesImage) {
            let urls = [];
            for (let i = 0; i < newTablesImage.length; i++) {
                const newImage = new File([newTablesImage[i]], newTablesImage[i].name, {type: newTablesImage[i].type});
                const storageRef = firebase.storage().ref();
                const imageRef = storageRef.child('tableImages/' + id + '/' + newTablesImage[i].name);
                const snap = await imageRef.put(newImage);
                const url = await snap.ref.getDownloadURL();
                urls.push(url);

            }
            await firestore.collection(PARTNERS).doc(id).update({
                tableImagesUrl: urls,
            });
        }
    } catch (e) {
        console.log('error al cargar imagenes', e);
    }
}

export const createPartner = () => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebaseReact = getFirebase();
        const firestore = getFirestore();
        const {partnerReducer} = getState();
        const {
            id,
            newUserName,
            newUserEmail,
            newUserPassword,
            plan_billing
        } = partnerReducer.createReducer;

        dispatch(toggleLoading())
        try {
            validateFields(partnerReducer.createReducer);

            await savePartner(firestore, partnerReducer.createReducer)

            await createUser(firestore, id, {email: newUserEmail, pass: newUserPassword, name: newUserName});

            try {
                await createSubscription(firebaseReact, {planId: plan_billing, partnerId: id});
            }
            catch (e) {
                console.log('error al crear subscripcion', e);
                dispatch(createSuccessWithoutSubscription("Partner creado, error al suscribirse: " + e))
                return;
            }
            dispatch(createSuccess())
        } catch (error) {
            console.log(error);
            dispatch(createError('Error al crear el partner'));
        }
    }
}

const createSubscription = async (f, data, retry = true) => {

    const callableFunction = f.app().functions(process.env.REACT_APP_CLOUD_FUNCTION_REGION).httpsCallable("subscribeToPlan");

    let result = await callableFunction(data);
    console.log('resultsss: ', result);
    const {result: res, checkout} = result.data;
    if (res === false || checkout === undefined || checkout === null || checkout === "") {
        if (retry) {
            return await createSubscription(f, data, false);
        }
        throw new Error('Error al crear la suscripcion: ' + result.data.details);
    }
};
